import { Card, Icon } from '@blueprintjs/core';
import moment from 'moment';
import { ETA } from '../../lib/actions/MatchAction';

type Props = {
  eta: ETA | null;
};

export default function ETASummary({ eta }: Props) {
  if (eta && eta.arrive_at)
    return (
      <Card className='flat-card'>
        <strong className='u-text--primary'>ETA</strong>
        <span className='u-pad__right--sm'> to next stop </span>
        <span className='u-pad__right--sm'>
          <Icon
            icon='time'
            size={16}
            style={{ top: -1, position: 'relative' }}
            className='u-text--primary'
          />
          <span> {moment(eta.arrive_at).fromNow()}</span>
        </span>
        {eta.distance && (
          <span>
            <Icon
              icon='route'
              size={16}
              style={{ top: -2, position: 'relative' }}
              className='u-text--primary'
            />
            {eta.distance && <span> {eta.distance} mi away</span>}
          </span>
        )}
      </Card>
    );

  return null;
}

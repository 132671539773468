import { Radio, RadioGroup } from '@blueprintjs/core';
import { Field, useField } from 'formik';
import { BaseSyntheticEvent, useCallback, useState } from 'react';
import { CargoStopItemValues } from '../estimate/steps/Cargo';
import DecimalInput from './DecimalInput';

type WeightInputProps = {
  parentName: string;
  name: string;
  currentWeight?: number;
};

export default function WeightInput({ parentName, name }: WeightInputProps) {
  const [{ value: item }, , { setValue }] =
    useField<CargoStopItemValues>(parentName);
  const [displayWeight, setDisplayWeight] = useState<number | undefined>();
  const [weightType, setWeightType] = useState<'total' | 'per_piece'>('total');
  const { pieces } = item;

  const handleWeightChange = useCallback(
    (e: BaseSyntheticEvent) => {
      const w = +e.target.value;
      let itemWeight;
      if (weightType === 'total' && pieces && w) {
        itemWeight = +(w / pieces).toFixed(2);
      } else if (w && weightType === 'per_piece') {
        itemWeight = w;
      }

      const weight = w && Math.round(w) ? Math.round(w) : undefined;
      setDisplayWeight(weight);
      setValue({ ...item, weight: itemWeight });
    },
    [item, pieces, setValue, weightType]
  );

  const handleWeightTypeChange = useCallback(
    (e: BaseSyntheticEvent) => {
      setValue({ ...item, weight: undefined });
      setDisplayWeight(undefined);
      setWeightType(e.target.value);
    },
    [item, setValue]
  );

  return (
    <>
      <RadioGroup
        label='WEIGHT'
        onChange={handleWeightTypeChange}
        selectedValue={weightType}
      >
        <Radio
          label='Total'
          className='u-display__inline-block'
          value={'total'}
        />
        &nbsp; &nbsp; &nbsp;
        <Radio
          label='Per Piece'
          className='u-display__inline-block'
          value={'per_piece'}
        />
      </RadioGroup>
      <DecimalInput
        name={`per-piece-weight-${name}`}
        label=''
        value={`${displayWeight}`}
        onChange={handleWeightChange}
        decimals={0}
        type='number'
        ignoreTouched={false}
        placeholder='lbs'
        disabled={!item.pieces && weightType === 'total'}
      />
      <Field type={'hidden'} name={name} value={item.weight?.toFixed(2)} />
    </>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { useParams, useSearchParams } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import NotFoundScreen from './NotFoundScreen';
import { useSocket } from '../lib/FraytSocket';
import MatchStopView from '../components/matches/MatchStopView';
import { useSelector } from 'react-redux';
import {
  selectMatchStop,
  trackMatchStop,
} from '../lib/reducers/matchStopSlice';
import { useAppDispatch } from '../lib/store';

export default function MatchTrackingScreen() {
  const socket = useSocket();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { matchStopId } = useParams();
  const matchStop = useSelector(selectMatchStop);

  const [loading, setLoading] = useState(true);

  const loadMatch = useCallback(async () => {
    const token = searchParams.get('token');

    if (matchStopId) {
      setLoading(true);
      await dispatch(trackMatchStop({ matchStopId, token }));
      setLoading(false);
    }
  }, [matchStopId, searchParams, dispatch]);

  useEffect(() => {
    loadMatch();
  }, [loadMatch]);

  useEffect(() => {
    return function cleanup() {
      socket.disconnect();
    };
  }, [socket]);
  if (!matchStopId) return null;
  if (!loading && !matchStop) return <NotFoundScreen />;

  return (
    <div className='appContent'>
      <Grid>
        <Row className='appCushion' />
        <Row>
          <Col xs={12} md={6}>
            {!loading && <h1 className='pageTitle'>Match</h1>}
          </Col>
        </Row>

        <Row>
          <Col xs={12} style={{ marginBottom: '20px', minHeight: '720px' }}>
            {loading ? (
              <div className='barLoaderContainer'>
                <BarLoader width={100} color='#ff9500' loading />
              </div>
            ) : (
              matchStop && <MatchStopView matchStop={matchStop} />
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

import { MatchSLA } from '../../lib/actions/MatchAction';
import { PublicMatch } from '../../lib/actions/MatchStopAction';
import { dateWithTZ } from '../../lib/Utility';

type Props = {
  match: PublicMatch;
  type: MatchSLA['type'];
};

function SLAContent({ match, type }: Props) {
  const { slas, timezone } = match;
  const sla = slas.find(sla => sla.type === type);

  if (!sla) return null;

  const { start_time, end_time } = sla;

  const startDate = dateWithTZ(start_time, timezone);
  const endDate = dateWithTZ(end_time, timezone);

  if (startDate.isSame(endDate, 'day'))
    return (
      <>
        <span className='u-text--caption u-text--size-1'>
          {startDate.format('MMM D, YYYY')}{' '}
        </span>
        <span>
          {startDate.format('h:mm A')} - {endDate.format('h:mm A')}
        </span>
        <span className='u-text--caption u-text--size-1'>
          {' '}
          {endDate.format('z')}
        </span>
      </>
    );

  return (
    <>
      <span className='u-text--caption u-text--size-1'>
        {startDate.format('MMM D, YYYY')}
      </span>
      <span>{startDate.format('h:mm A')}</span> -
      <span className='u-text--caption u-text--size-1'>
        {endDate.format('MMM D, YYYY')}
      </span>
      <span>{endDate.format('h:mm A')}</span>
      <span className='u-text--caption u-text--size-1'>
        {endDate.format('z')}
      </span>
    </>
  );
}

export default function SLARange(props: Props) {
  return (
    <p className='u-push__bottom--sm u-push__top--xs'>
      <SLAContent {...props} />
    </p>
  );
}

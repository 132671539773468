import { Card, Icon, ProgressBar } from '@blueprintjs/core';
import 'moment-timezone';
import { PublicMatchStop } from '../../lib/actions/MatchStopAction';
import {
  formatDateVerbose,
  formatFullMatchStopState,
  formatPublicMatchStopStatus,
} from '../../lib/Utility';
import AddressSummary from './AddressSummary';
import MatchVehicleCards from './cards/MatchVehicleCards';
import MatchTimestampCards from './cards/MatchTimestampCards';
import SLARange from './SLARange';

type MatchStopPanelProps = {
  matchStop: PublicMatchStop;
};

export default function MatchStopPanel({ matchStop }: MatchStopPanelProps) {
  const progress = formatFullMatchStopState(matchStop);
  const { match } = matchStop;

  return (
    <div>
      <Card interactive={false}>
        <h2 data-testid='current_status'>
          {formatPublicMatchStopStatus(matchStop)}
        </h2>

        <ProgressBar
          intent={progress.intent}
          value={progress.value}
          stripes={progress.stripes}
        />

        <div className='cardInset'>
          <div className='cardInset--icon'>
            <Icon icon='arrow-up' iconSize={20} />
          </div>
          <div className='cardInset--data'>
            <p className='statusDescription'>PICKUP</p>
            <AddressSummary address={match.origin_address} />
            <SLARange match={match} type='pickup' />
          </div>
        </div>

        <div className='cardInset'>
          <div className='cardInset--icon'>
            <Icon icon='arrow-down' iconSize={20} />
          </div>
          <div className='cardInset--data'>
            <p className='statusDescription'>DROPOFF</p>
            <AddressSummary address={matchStop.destination_address} />
            <SLARange match={match} type='delivery' />
          </div>
        </div>

        <div className='cardInset'>
          <div className='cardInset--icon'>
            <Icon icon='calendar' size={22} />
          </div>
          <div className='cardInset--data'>
            <p className='statusDescription'>MATCH DATE</p>
            <h2>{formatDateVerbose(match.inserted_at, match.timezone)}</h2>
          </div>
        </div>

        <div className='cardInset'>
          <div className='cardInset--data'>
            <p className='statusDescription'>MATCH #</p>
            <h2>{match.shortcode}</h2>
          </div>
        </div>

        <div className='cardInset'>
          <div className='cardInset--data'>
            <p className='statusDescription'>PO #</p>
            <h2>{matchStop.po || match.po || '-'}</h2>
          </div>
        </div>

        <MatchVehicleCards vehicleClass={match.vehicle_class} />
        <MatchTimestampCards match={match} />
      </Card>
    </div>
  );
}

import { Col, Row } from 'react-flexbox-grid';
import MatchStopMap from './MatchStopMap';
import MatchStopPanel from './MatchStopPanel';
import { PublicMatchStop } from '../../lib/actions/MatchStopAction';
import ETASummary from './ETASummary';
import { getActiveMatchStopEta } from '../../lib/Utility';

type MatchStopPanelProps = {
  matchStop: PublicMatchStop;
};

export default function MatchStopView({ matchStop }: MatchStopPanelProps) {
  return (
    <Row>
      <Col xs={12} lg={4} className='statusPane'>
        <MatchStopPanel matchStop={matchStop} />
      </Col>

      <Col xs={12} lg={8}>
        <ETASummary eta={getActiveMatchStopEta(matchStop)} />
        <MatchStopMap matchStop={matchStop} />
      </Col>
    </Row>
  );
}

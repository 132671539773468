import { Col, Row } from 'react-flexbox-grid';
import MatchMap from './MatchMap';
import { Match, MatchState } from '../../lib/actions/MatchAction';
import MatchPanel from './MatchPanel';
import { getActiveEta, matchInState } from '../../lib/Utility';
import { useMemo } from 'react';
import MatchActions from './MatchActions';
import ETASummary from './ETASummary';

type MatchPanelProps = {
  match: Match;
};

export default function MatchView({ match }: MatchPanelProps) {
  const eta = useMemo(() => getActiveEta(match), [match]);

  const isMatchEnRoute = () => {
    if (!match.stops) return false;

    return matchInState(match, [
      MatchState.PickedUp,
      MatchState.ArrivedAtPickup,
      MatchState.EnRouteToPickup,
    ]);
  };

  return (
    <Row>
      <Col xs={12} lg={4} className='statusPane'>
        <MatchPanel match={match} />
      </Col>

      <Col xs={12} lg={8}>
        <ETASummary eta={eta} />
        <MatchMap match={match} isEnRoute={isMatchEnRoute()} />
        <MatchActions match={match} />
      </Col>
    </Row>
  );
}
